import BaseBean from "@/utils/BaseBean";
import QpCard from "@/views/project/order/fy/qp/QpCard.vue";
import BgzlCard from "@/views/project/order/fy/bgzl/BgzlCard.vue";
export interface IHdxxListDataObj {
    utilInst:HdxxListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class HdxxListUtil extends BaseBean{
    public dataObj:IHdxxListDataObj

    constructor(proxy:any,dataObj:IHdxxListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.otherParams.networkData=await this.utils.Api.buildNetworkData();
    }
    //得到的选择行
    public getSelectedRow():any{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.proxy.$message(this.proxy.$t('请选择要操作的行'));
            return;
        }
        return selectRows;
    }
    //维护货代信息
    public async doHdxx(id:string,addOrLoad:string):Promise<void>{
        if('/add'==addOrLoad){
            //到底是为哪个子订单新增的货代信息呢?通过程序从列表打开卡片没有地方设置参数，就取巧给列表引擎赋值一个属性传给卡片，
            //卡片打开的时候拿到这个参数，再赋值给表单
            this.dataObj.pageListRef.listParam.childOrderId=id;
            await this.dataObj.pageListRef.addHandler();
        }else{
            await this.dataObj.pageListRef.editHandler({F_ID:id});
        }
    }
    //启票
    public async doQp(id:string,type:number):Promise<void>{
        //由于传入了id，因此addOrEdit不管传与不传，在cardEngine那里都会被赋值edit
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'edit',modelComp:QpCard,ownerComp:this.dataObj.pageListRef,
            type:type,
            cardFrom:'HdxxList',overflow:'hidden',title: '启票',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
    //确认发货通知
    public doQrfhtz(selectRows:any):void{
        this.utils.Tools.configBox({
            message:'确定要对<span style="color: red;font-weight: bold;font-size: 20px;">'+selectRows[0]['F_DZH']+'</span>发起发运通知操作吗?',
            distinguishCancelAndClose:true,
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.qrfhtzAction({params:{id:selectRows[0]['F_ID'],type:selectRows[0]['F_TYPE']}});
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.pageListRef.queryHandler();
                }
            }
        });
    }
    //维护报关资料
    public async doWhbgzl(selectRows:any):Promise<any>{
        //由于传入了id，因此addOrEdit不管传与不传，在cardEngine那里都会被赋值edit
        await this.utils.UtilPub.openDialog({id:selectRows[0]['F_ID'],proxy:this.proxy,addOrEdit:'edit',modelComp:BgzlCard,ownerComp:this.dataObj.pageListRef,
            type:selectRows[0]['F_TYPE'],
            cardFrom:'HdxxList',overflow:'hidden',title: '维护报关资料',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
    //清关
    public async doQg():Promise<any>{
        let res=await this.utils.OrderProviderApi.qgAction({params:{id:this.dataObj.otherParams.selectRow['F_ID'],type:this.dataObj.otherParams.selectRow['F_TYPE']}});
        if(res.result){
            this.utils.Tools.success();
            this.proxy.pageListRef.queryHandler();
            this.dataObj.otherParams.visible=false;
        }
    }
}