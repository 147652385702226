export default {
    zh:{
        hdxx:{
            queryParam:{
                dzh:'单证号',
                dzh_placeholder:'请输入单证号',
                htbh:'合同编号',
                htbh_placeholder:'请输入合同编号',
                status:'发运状态',
                status_placeholder:'请选择发运状态',
            },
            columns:{
                F_DATE:'生成日期',
                F_DZH:'单证号',
                F_WTBH:'委托编号',
                F_STATUS:'发运状态',
            },
            form:{
                tkType:'条款类型',
                tkType_placeholder:'请选择条款类型',
                tkType_rule: '请选择条款类型',
                dcType:'订舱模式',
                dcType_placeholder:'请选择订舱模式',
                dcType_rule: '请选择订舱模式',
                wtbh:'委托编号',
                wtbh_placeholder:'请输入委托编号',
                hdgsId:'货代公司',
                hdgsId_placeholder:'请选择货代公司',
                fhrGsmc:'公司名称',
                fhrGsmc_placeholder:'请输入发货人公司名称',
                fhrLxdh:'联系电话',
                fhrLxdh_placeholder:'请输入发货人联系电话',
                fhrLxdz:'联系地址',
                fhrLxdz_placeholder:'请输入发货人联系地址',
                shrGsmc:'公司名称',
                shrGsmc_placeholder:'请输入收货人公司名称',
                shrLxdh:'联系电话',
                shrLxdh_placeholder:'请输入收货人联系电话',
                shrLxdz:'联系地址',
                shrLxdz_placeholder:'请输入收货人联系地址',
                tzrGsmc:'公司名称',
                tzrGsmc_placeholder:'请输入通知人公司名称',
                tzrLxdh:'联系电话',
                tzrLxdh_placeholder:'请输入通知人联系电话',
                tzrLxdz:'联系地址',
                tzrLxdz_placeholder:'请输入通知人联系地址',
                mz:'毛重(Kg)',
                mz_placeholder:'请输入毛重',
                jz:'净重(Kg)',
                jz_placeholder:'请输入净重',
                tj:'体积(m³)',
                tj_placeholder:'请输入体积',
                bzfsSl:'包装数量',
                bzfsSl_placeholder:'请输入包装方式和数量',
                hwms:'货物描述',
                hwms_placeholder:'请输入货物描述',
                fyg:'发运岗',
                fyg_placeholder:'请输入发运岗',
                mdd:'目的岗',
                mdd_placeholder:'请输入目的岗',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                xt:'噱头',
                xt_placeholder:'请输入噱头',
                hgbh:'海关编号',
                hgbh_placeholder:'请输入海关编号',
                htbh:'合同编号',
                htbh_placeholder:'请输入合同编号',
                remark:'备注',
                remark_placeholder:'请输入备注',
                date:'生成日期',
                date_placeholder:'请选择生成日期',
            },
        }
    },
    en:{
        hdxx:{
            queryParam:{
                dzh:'单证号',
                dzh_placeholder:'请输入单证号',
                htbh:'合同编号',
                htbh_placeholder:'请输入合同编号',
                status:'发运状态',
                status_placeholder:'请选择发运状态',
            },
            columns:{
                F_DATE:'生成日期',
                F_DZH:'单证号',
                F_WTBH:'委托编号',
                F_STATUS:'发运状态',
            },
            form:{
                tkType:'条款类型',
                tkType_placeholder:'请选择条款类型',
                tkType_rule: '请选择条款类型',
                dcType:'订舱模式',
                dcType_placeholder:'请选择订舱模式',
                dcType_rule: '请选择订舱模式',
                wtbh:'委托编号',
                wtbh_placeholder:'请输入委托编号',
                hdgsId:'货代公司',
                hdgsId_placeholder:'请选择货代公司',
                fhrGsmc:'发货人公司名称',
                fhrGsmc_placeholder:'请输入发货人公司名称',
                fhrLxdh:'发货人联系电话',
                fhrLxdh_placeholder:'请输入发货人联系电话',
                fhrLxdz:'发货人联系地址',
                fhrLxdz_placeholder:'请输入发货人联系地址',
                shrGsmc:'收货人公司名称',
                shrGsmc_placeholder:'请输入收货人公司名称',
                shrLxdh:'收货人联系电话',
                shrLxdh_placeholder:'请输入收货人联系电话',
                shrLxdz:'收货人联系地址',
                shrLxdz_placeholder:'请输入收货人联系地址',
                tzrGsmc:'通知人公司名称',
                tzrGsmc_placeholder:'请输入通知人公司名称',
                tzrLxdh:'通知人联系电话',
                tzrLxdh_placeholder:'请输入通知人联系电话',
                tzrLxdz:'通知人联系地址',
                tzrLxdz_placeholder:'请输入通知人联系地址',
                mz:'毛重',
                mz_placeholder:'请输入毛重',
                jz:'净重',
                jz_placeholder:'请输入净重',
                tj:'体积',
                tj_placeholder:'请输入体积',
                bzfsSl:'包装方式和数量',
                bzfsSl_placeholder:'请输入包装方式和数量',
                hwms:'货物描述',
                hwms_placeholder:'请输入货物描述',
                fyg:'发运岗',
                fyg_placeholder:'请输入发运岗',
                mdd:'目的岗',
                mdd_placeholder:'请输入目的岗',
                jgtk:'价格条款',
                jgtk_placeholder:'请输入价格条款',
                ysfs:'运输方式',
                ysfs_placeholder:'请输入运输方式',
                xt:'噱头',
                xt_placeholder:'请输入噱头',
                hgbh:'海关编号',
                hgbh_placeholder:'请输入海关编号',
                htbh:'合同编号',
                htbh_placeholder:'请输入合同编号',
                remark:'备注',
                remark_placeholder:'请输入备注',
                date:'生成日期',
                date_placeholder:'请选择生成日期',
            },
        }
    }
}