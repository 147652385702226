import HdxxCard from './HdxxCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import HdxxListUtil,{IHdxxListDataObj} from './hdxxListUtil';
import language from './hdxxLanguage'
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
export default defineComponent ({
    name: 'HdxxList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IHdxxListDataObj=reactive<IHdxxListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                // showRadio:true,
                gridTitle:'发运管理',
                isShowFixCol:false,
                queryParam: {},
                modelComp: HdxxCard,
                cardFrom:'HdxxList',
                modelMethod: utils.OrderProviderApi.buildUrl('/hdxx/pageData')
            },
            otherParams:{
                selectRow:{},//清关时点击的行
                visible:false,//清关弹出框显示/隐藏控制字段
                belongMaxId:'',//清关单据相关
                mapCount:{},//统计未办理的各个步骤数量
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new HdxxListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'未发运'},{value:1,label:'已维护货代信息'},{value:2,label:'已启票'},{value:3,label:'已发运通知'},{value:4,label:'已上传报关单'}]
                }
            }
        })
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.selectRow['F_ID'],
                    fileSize:80,
                    accept:'.pdf',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_ORDER_CODE,
                cardFrom:'HdxxList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'HdxxList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //维护货代信息
        const hdxxHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                await dataObj.utilInst.doHdxx(selectRows[0]['F_ID'],selectRows[0]['F_STATUS']==0?'/add':'/load');
            }
        }
        //启票
        const qpHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(0==selectRows[0]['F_STATUS']){
                    proxy.$message('请先维护货代信息');return;
                }
                if(0==selectRows[0]['F_JS_FLAG']){
                    proxy.$message('该订单还未完成结算，不能启票');
                }else{
                    await dataObj.utilInst.doQp(selectRows[0]['F_ID'],selectRows[0]['F_TYPE']);
                }
            }
        }
        //确认发货通知
        const qrfhtzHandler=()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(2!=selectRows[0]['F_STATUS']){//只有==2，即完成启票信息的填写，才能发起发货通知，发起之后就不能再次发起了，因为发起之后F_STATUS就变成3了
                    proxy.$message({type:'warning',message:'当前状态不能发起发货通知'});
                }else{
                    dataObj.utilInst.doQrfhtz(selectRows);
                }
            }
        }
        //维护报关资料
        const whbgzlHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(3!=selectRows[0]['F_STATUS']){
                    proxy.$message({type:'warning',message:'请先确认发货通知'});
                }else{
                    await dataObj.utilInst.doWhbgzl(selectRows);
                }
            }
        }
        //清关
        const qgHandler=()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(4!=selectRows[0]['F_STATUS']){//只有完成报关资料的上传与填写之后，才能清关
                    proxy.$message({type:'warning',message:'请先维护报关资料'});
                }else{
                    dataObj.otherParams.selectRow=selectRows[0];
                    dataObj.otherParams.visible=true;
                    nextTick(()=>{
                        dataObj.refMap.get('qgdj').uploadParams.belongMaxId=dataObj.otherParams.selectRow['F_ID'];
                    })
                }
            }
        }
        //确认清关
        const sureQgHandler=async ()=>{
            if(dataObj.refMap.get('qgdj').fileList.length==0) {
                proxy.$message({type:'error',message:'至少上传一份清关单据'});
                return false;
            }else{
                await dataObj.utilInst.doQg();
            }
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.mapCount=res.mapCount;
        }
        return{
            ...toRefs(dataObj),comboSelect,buildUploadInitParams,handlePreview,formatPageInfo,orderDetail,piDetail
            ,hdxxHandler,qpHandler,qrfhtzHandler,whbgzlHandler,qgHandler,sureQgHandler,gridLoaded
        }
    }
});