import BaseBean from "@/utils/BaseBean";
import BgzlContentCard from "@/views/project/order/fy/bgzl/content/BgzlContentCard.vue";
import HtxxdCard from "@/views/project/order/fy/zl/bgzl/htxxd/HtxxdCard.vue";
import XdxxdCard from "@/views/project/order/fy/zl/bgzl/xdxxd/XdxxdCard.vue";
import FpxxdCard from "@/views/project/order/fy/zl/bgzl/fpxxd/FpxxdCard.vue";

export interface IBgzlCardDataObj {
    utilInst:BgzlCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class BgzlCardUtil extends BaseBean{
    public dataObj:IBgzlCardDataObj;

    constructor(proxy:any,dataObj:IBgzlCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
    //打开合同信息单、箱单信息单、发票信息单的表单
    public async openForm(id:string,childOrderId:string,type:number,title:string):Promise<any>{
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'/load',modelComp:BgzlContentCard,ownerComp:this.proxy,
            type:type,title: title,childOrderId:childOrderId,
            cardFrom:'BgzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
    //打开合同信息单、箱单信息单、发票信息单的pdf
    public async openPdf(id:string,childOrderId:string,type:number,title:string):Promise<any>{
        let modelComp:any=HtxxdCard;
        if(type==1){
            modelComp=XdxxdCard;
        }else if(type==2){
            modelComp=FpxxdCard;
        }
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'/load',modelComp:modelComp,ownerComp:this.proxy,
            type:type,title: title,childOrderId:childOrderId,
            cardFrom:'BgzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }
}