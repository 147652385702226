import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import QpCardUtil,{IQpCardDataObj} from './qpCardUtil';
import QptzdCard from "@/views/project/order/fy/zl/qptzd/QptzdCard.vue";
export default defineComponent({
    name:'QpCard',
    title:'启票信息',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IQpCardDataObj=reactive<IQpCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    qpDetail:{},
                },
                details:['qpDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/qp')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                qpdwmc: [utils.UtilPub.commonValidRule('请输入启票单位名称')],
                scdwmc: [utils.UtilPub.commonValidRule('请输入收车单位名称')],
            },
            otherParams:{
                childOrderId:'',//拆单明细id
                type:''//订单是否进行了拆单，拆单了是1，为拆单是0，拆单了，上面的id是拆单明细id，没有拆单，上面的id是订单id
            }
        })
        onBeforeMount(()=>{

            dataObj.utilInst=new QpCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'车辆信息',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/qp/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.childOrderId=res.data.childOrderId;
            if(!res.data.id)addOrLoad='/add'
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            //tmd,当新增的时候，需要把子订单的分单详情展示出来，所以要把子订单id传到后台，保存之后，就不会用到子订单id去查询了
            return {cardFrom:engineInst.engineParams.cardFrom,childOrderId:engineInst.engineParams.id,type:engineInst.engineParams.type};
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            if('qpDetail'==options.detailType){}
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let qpDetailData=dataObj.refMap.get('qpDetail').getDetailData();
            if(qpDetailData.length==0){
                proxy.$message({type:'warning',message:'车辆信息不能为空'});
                return false;
            }
            let result=qpDetailData.findIndex((row:any,index:number)=>{
               if(!row.F_CX || !row.F_XZPZ || !row.F_COLOR || (!row.F_NUM || row.F_NUM==0) || (!row.F_PRICE || row.F_PRICE==0)){
                   utils.Tools.warning({message:'第'+(index+1)+'行数据有误或不全'});
               }
               return !row.F_CX || !row.F_XZPZ || !row.F_COLOR || (!row.F_NUM || row.F_NUM==0) || (!row.F_PRICE || row.F_PRICE==0)
            })
            return result==-1;
        }
        //计算某行的总金额
        const rowAmount=computed(()=>{
            return (row:any) => {
                if(row.F_NUM && row.F_PRICE){
                    let amount=row.F_NUM*row.F_PRICE;
                    row.F_AMOUNT=amount;
                    return amount;
                }
                row.F_AMOUNT=0;
                return 0;
            };
        })
        //明细表格加载完毕事件
        const gridLoaded=(detailType:string,res:any)=>{
            res.rows.forEach((row:any)=> {
                if(!row.F_ID)dataObj.refMap.get(detailType).setInsert(row);
            });
        }
        //预览启票通知单
        const preViewQptzdHandler=async ()=>{
            await utils.UtilPub.openDialog({id:dataObj.form.childOrderId,proxy:proxy,addOrEdit:'/load',modelComp:QptzdCard,ownerComp:proxy.engine.engineParams.ownerComp,
                cardFrom:'QpCard',overflow:'hidden',title: '启票通知单',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),proxy,cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,setParam,beforeSaveHandler
         ,rowAmount,gridLoaded,preViewQptzdHandler
        }
    }
});